










import {
  defineComponent,
} from '@nuxtjs/composition-api';
import LazyHydrate from 'vue-lazy-hydration';

import ContentBlocks from '~/components/ContentBlocks.vue';

export default defineComponent({
  name: 'ContactInfo',
  components: {
    LazyHydrate,
    ContentBlocks,
  },
});
