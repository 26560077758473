import { Ref, ref, useContext } from '@nuxtjs/composition-api';

import request from './gql/contact.gql';
import {
  UseContactUsErrors,
  UseContactUsInterface,
  UseContactUsRequestParams, UseContactUsResult,
} from './useContactUs';

export function useContactUs(): UseContactUsInterface {
  const { app } = useContext();
  const loading: Ref<boolean> = ref(false);
  const result: Ref<UseContactUsResult> = ref({ result: null });
  const error: Ref<UseContactUsErrors> = ref({ request: null });

  /**
   * Sending contact us post
   *
   * @param contactParams
   */
  const contact = async (contactParams: UseContactUsRequestParams) => {
    try {
      loading.value = true;
      const { data, errors } = await app.context.$vsf.$magento.api.customMutation({
        mutation: request,
        mutationVariables: {
          ...contactParams,
          input: {
            comment: contactParams.comment,
            email: contactParams.email,
            name: contactParams.name,
            telephone: contactParams.phone,
          },
        },
      });
      result.value.result = data.contactUs.message;

      if (!data.contactUs || !data.contactUs.status || (errors && errors.length > 0)) {
        error.value.request = 'Something went wrong. Please try again later.';
      }
    } catch (error_) {
      error.value.request = error_;
    } finally {
      loading.value = false;
    }
  };

  return {
    loading,
    request: contact,
    result,
    error,
  };
}
