






















import {
  defineComponent,
} from '@nuxtjs/composition-api';

import SvgImage from '~/components/General/SvgImage.vue';

export default defineComponent({
  name: 'Field',
  components: { SvgImage },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
    },
  },
});
