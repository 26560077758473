var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj;

var handleSubmit = ref.handleSubmit;return [_c('form',{class:_vm.$style.form,on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onFormSubmit)}}},[_c('h2',{class:_vm.$style.title},[_vm._v("\n        "+_vm._s(_vm.$t('Contact Form'))+"\n      ")]),_vm._v(" "),_c('p',{class:_vm.$style.text},[_vm._v("\n        "+_vm._s(_vm.$t('You can use this form to contact us.'))+"\n      ")]),_vm._v(" "),_c('div',{class:[_vm.$style.message, ( _obj = {}, _obj[_vm.$style.error] = _vm.error.request, _obj[_vm.$style.success] = _vm.result.result, _obj )]},[_vm._v("\n        "+_vm._s(_vm.error.request ? _vm.error.request : _vm.result.result)+"\n      ")]),_vm._v(" "),_c('fieldset',{class:_vm.$style.fieldset},[_vm._l((_vm.formFields),function(ref){
var field = ref.field;
var errorLabel = ref.errorLabel;
var label = ref.label;
var type = ref.type;
var options = ref.options;
var defaultOption = ref.defaultOption;
var rules = ref.rules;
var icon = ref.icon;
return _c('Field',{key:field,attrs:{"name":field,"label":label,"icon-name":icon,"required":rules && rules.includes('required')}},[_c('ValidationProvider',{attrs:{"rules":rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(type === 'input')?_c('SfInput',{attrs:{"id":field,"valid":!errors[0],"error-message":_vm.$t(errors[0]).replace('The field', errorLabel || label),"name":field},model:{value:(_vm.formCopy[field]),callback:function ($$v) {_vm.$set(_vm.formCopy, field, $$v)},expression:"formCopy[field]"}}):(type === 'select')?_c('SfSelect',{attrs:{"id":field,"valid":!errors[0],"error-message":'Please select an enquiry type from the list so we know where to direct your message',"name":field},model:{value:(_vm.formCopy[field]),callback:function ($$v) {_vm.$set(_vm.formCopy, field, $$v)},expression:"formCopy[field]"}},[(defaultOption)?_c('SfSelectOption',{attrs:{"value":""}},[_vm._v("\n                "+_vm._s(defaultOption)+"\n              ")]):_vm._e(),_vm._v(" "),_vm._l((options),function(option,index){return _c('SfSelectOption',{key:index,attrs:{"value":option}},[_vm._v("\n                "+_vm._s(option)+"\n              ")])})],2):_c('SfTextarea',{attrs:{"id":field,"valid":!errors[0],"error-message":_vm.$t(errors[0]).replace('The field', errorLabel),"name":field,"cols":"5","rows":"3"},model:{value:(_vm.formCopy[field]),callback:function ($$v) {_vm.$set(_vm.formCopy, field, $$v)},expression:"formCopy[field]"}})]}}],null,true)})],1)}),_vm._v(" "),_c('div',{class:_vm.$style.submitWrapper},[_c('input',{attrs:{"id":"hideIt","type":"hidden","name":"hideIt","value":""}}),_vm._v(" "),_c('button',{class:_vm.$style.submit,attrs:{"type":"submit","title":"Send message"}},[_c('span',[_vm._v("Send message")])])])],2)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }