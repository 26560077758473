















import { defineComponent } from '@nuxtjs/composition-api';

import HTMLContent from '~/components/common/HTMLContent.vue';

export default defineComponent({
  name: 'ContentBlock',
  components: { HTMLContent },
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    content: {
      type: String,
      required: false,
      default: '',
    },
  },
});
