





























































































import {
  defineComponent, ref,
} from '@nuxtjs/composition-api';
import {
  SfInput, SfSelect, SfTextarea,
} from '@storefront-ui/vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, required } from 'vee-validate/dist/rules';

import { ContactUsFormFields } from '~/modules/contactUs/components/forms/types';
import { useContactUs } from '~/modules/contactUs/composables/useContactUs';

import Field from '../Field/Field.vue';

extend('email', {
  ...email,
  message: 'Invalid email',
});

extend('required', {
  ...required,
  message: 'The field is required',
});

export default defineComponent({
  name: 'ContactForm',
  components: {
    Field,
    SfInput,
    SfSelect,
    SfTextarea,
    ValidationObserver,
    ValidationProvider,
  },
  setup() {
    const { request, result, error } = useContactUs();

    const formCopy = ref<ContactUsFormFields>({
      name: '',
      email: '',
      phone: '',
      enquiry: '',
      comment: '',
    });

    const onFormSubmit = async () => {
      await request(formCopy.value);
    };

    return {
      formCopy,
      onFormSubmit,
      error,
      result,
    };
  },
  data() {
    return {
      formFields: [
        {
          field: 'name',
          label: 'Name',
          type: 'input',
          rules: 'required',
        },
        {
          field: 'email',
          label: 'Email',
          type: 'input',
          rules: 'required|email',
        },
        {
          field: 'phone',
          label: 'Phone',
          type: 'input',
        },
        {
          field: 'enquiry',
          label: 'Subject',
          type: 'select',
          rules: 'required',
          options: [
            'I need to make changes to my order',
            'I can\'t find my printer/ cartridges on your site',
            'I\'d like to place an order',
            'I have a question about an existing order',
            'I\'d like to comment on your service or website',
            'I have a problem with my cartridges',
            'Other',
          ],
          defaultOption: '-- Please Select --',
          icon: 'chevron-down',
        },
        {
          field: 'comment',
          label: 'Message',
          errorLabel: 'Comment',
          type: 'textArea',
          rules: 'required',
        },
      ],
    };
  },
});
