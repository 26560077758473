



















import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';

import { APP_ROUTER_PATHS } from '~/constants';
import { getFullUrl } from '~/helpers/urlHelpers';

import ContactForm from './components/Form/Form.vue';
import ContactInfo from './components/Info/Info.vue';
import ContactMap from './components/Map/Map.vue';

export default defineComponent({
  name: 'ContactUsPage',
  components: {
    ContactForm,
    ContactInfo,
    ContactMap,
  },
  setup() {
    const metaInfo = computed(() => {
      const description = [{
        hid: 'description',
        name: 'description',
        content: 'You can contact us by online form, email address, phone number and even fax. Our customer service team are based in the UK and answer emails within one business day.',
      }];

      return {
        title: 'Contact Us',
        meta: description,
        link: [
          { rel: 'canonical', href: getFullUrl(APP_ROUTER_PATHS.contact) },
        ],
      };
    });

    return {
      metaInfo,
    };
  },
  data() {
    return {
      title: 'We\'re here to help',
    };
  },
  head() {
    return this.metaInfo;
  },
});
